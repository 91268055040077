jQuery( document ).ready(function() {


     //Hochzeit
      
      
     setTimeout(function () {
      //Name
      jQuery("[data-uid='WWNJ-XO1X']").addClass("active");
      jQuery("[data-parent-uid='WWNJ-XO1X']").addClass("active");
      jQuery(".wpc-control-lists.wpc-sub-control select").append("<option>Wähle Schriftart</option>");
    jQuery(".wpc-control-lists.wpc-sub-control select option").removeAttr("selected");
    jQuery(".wpc-control-lists.wpc-sub-control select option").attr("selected", "selected");

    }, 500);
    

     var timeout = null;

     // Listen for keystroke events
     jQuery("[data-uid = 'WWNJ-XO1X'] input").keyup(function () {
       // Clear the timeout if it has already been set.
       // This will prevent the previous task from executing
       // if it has been less than <MILLISECONDS>
       clearTimeout(timeout);
       var konfigQuery = window.matchMedia('screen and (min-width: 0px) and (max-width: 767px)');
       // Make a new timeout set to go off in 1000ms (1 second)
       timeout = setTimeout(function () {
         if (konfigQuery.matches) {

            jQuery('html, body').animate({
             scrollTop: jQuery('.hochzeitskonfigurator,.deine-hochzeits-konfiguration').offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
           }, 'fast');
          

         }

         
       }, 1000);

     });



    //Start


jQuery("[data-text='Name']").click(function () {
  if(jQuery(this).hasClass("wpc-group-active")){
  jQuery("[data-uid='WWNJ-XO1X'].wpc-control-item").removeClass("active");}
});
jQuery("[data-text='Pusteblume']").click(function () {
  
  //Name
  jQuery("[data-uid='WWNJ-XO1X'].wpc-control-item").addClass("active");
  jQuery("[data-parent-uid='WWNJ-XO1X']").addClass("active");
  //Name2
  jQuery("[data-uid='0B3W-RBlT'].wpc-control-item").removeClass("active");
  jQuery("[data-parent-uid='0B3W-RBlT']").removeClass("active");
   
});
jQuery("[data-text='Herzen']").click(function () {
  //Name
  jQuery("[data-uid='WWNJ-XO1X'].wpc-control-item").addClass("active");
  jQuery("[data-parent-uid='WWNJ-XO1X']").addClass("active");
  //Name2
  jQuery("[data-uid='0B3W-RBlT'].wpc-control-item").removeClass("active");
  jQuery("[data-parent-uid='0B3W-RBlT']").removeClass("active");
});
jQuery("[data-text='Vogel']").click(function () {
  //Name2
  jQuery("[data-uid='0B3W-RBlT'].wpc-control-item").addClass("active");
  jQuery("[data-parent-uid='0B3W-RBlT']").addClass("active");
  //Name
  jQuery("[data-uid='WWNJ-XO1X'].wpc-control-item").removeClass("active");
  jQuery("[data-parent-uid='WWNJ-XO1X']").removeClass("active");
});



   jQuery(".wpc-control-lists-inner[data-parent-uid='gchM-1UGd'] li").click(function () {

        if (jQuery(".wpc-control-lists-inner[data-parent-uid='gchM-1UGd'] .wpc-layer-type-image").hasClass("current")) {

            jQuery("[data-uid='zwKh-AZqo']").addClass('image-chosen');
            jQuery("[data-uid='gchM-1UGd'] .wpc-layer-type-text").addClass('image-chosen');
            console.log("had class");
       
      } else{
        jQuery(".wpc-carousel-stage-outer [data-uid='zwKh-AZqo']").removeClass('image-chosen');
        jQuery("[data-uid='gchM-1UGd'] .wpc-layer-type-text").removeClass('image-chosen');
        console.log("nope");
      
      }});
   
    
      jQuery("[data-uid='zwKh-AZqo'] input").click(function() { 
        console.log('clickcheck');
        jQuery(".subset[data-uid='zwKh-AZqo']").removeClass('image-chosen');
      });

      jQuery("[data-uid='WWNJ-XO1X'] input").keyup(function() { 
       console.log('keyup');

        jQuery(".subset[data-uid='zwKh-AZqo']").removeClass('image-chosen');
       // jQuery("[data-uid='gchM-1UGd'] .wpc-layer-type-text").removeClass('image-chosen');

        var txtlen1 = jQuery("[data-uid = '7dNd-ahQQ'] input").val().length;
        //var txtlen2 = jQuery("[data-uid = 'Kfml-rbdv'] p").val().length; 
        var txtlen2 = jQuery("[data-uid = '8Gt8-LI0E'] input").val().length;
        var txtlen3 = jQuery("[data-uid = 'TkCe-feK9'] input").val().length;
        console.log("1:"+txtlen1);
        console.log("2:"+txtlen2);
        console.log("3:"+txtlen3);
        
        
          jQuery("[data-uid = '7dNd-ahQQ'] p").removeClass();
          //jQuery("[data-uid = 'Kfml-rbdv'] p").removeClass();
          jQuery("[data-uid = '8Gt8-LI0E'] p").removeClass();
          jQuery("[data-uid = 'TkCe-feK9'] p").removeClass();

        
        if(txtlen1 >=8 || txtlen2 >=8 || txtlen3 >=8){
            
            jQuery("#wpc-vorderseite-32713 .wpc-text-layer p").addClass("fontsize22");
            jQuery("#wpc-vorderseite-32713 [data-uid = '8Gt8-LI0E'] p").addClass("fontsizeAnd");
            
          } });});