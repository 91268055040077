jQuery( document ).ready(function() {


     //Hochzeit

     var timeout = null;

     // Listen for keystroke events
     jQuery("[data-uid = 'WWNJ-XO1X'] input").keyup(function () {
       // Clear the timeout if it has already been set.
       // This will prevent the previous task from executing
       // if it has been less than <MILLISECONDS>
       clearTimeout(timeout);

       // Make a new timeout set to go off in 1000ms (1 second)
       timeout = setTimeout(function () {
         if (konfigQuery.matches) {

            jQuery('html, body').animate({
             scrollTop: jQuery('.hochzeitskonfigurator,.deine-hochzeits-konfiguration').offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
           }, 'fast');

         }
       }, 1000);
     });


    setTimeout(function () {
            
        jQuery('.subset.wpc-text-layer[data-uid="zwKh-AZqo"]').addClass("image-chosen");
        jQuery('.wpc-text-layer[data-uid="TkCe-feK9"]').addClass('taube');
        jQuery('.wpc-text-layer[data-uid="7dNd-ahQQ"]').addClass('taube');
        jQuery('.wpc-text-layer[data-uid="8Gt8-LI0E"]').addClass('taube');
        jQuery('.wpc-text-layer[data-uid="Jsee-5zIb"]').addClass('taube');
      }, 1000);
    

        

    jQuery('.wpc-control-item.wpc-layer-type-image[data-uid="Ed2v-K7ZX"]').click(function () {
        if(jQuery('.wpc-text-layer[data-uid="TkCe-feK9"]').hasClass("taube")){
            //silence
        }else{
            jQuery('.wpc-text-layer[data-uid="TkCe-feK9"]').addClass('taube');
            jQuery('.wpc-text-layer[data-uid="7dNd-ahQQ"]').addClass('taube');
            jQuery('.wpc-text-layer[data-uid="8Gt8-LI0E"]').addClass('taube');
            jQuery('.wpc-text-layer[data-uid="Jsee-5zIb"]').addClass('taube');
        }
        
        

    });
    jQuery('.wpc-control-item.wpc-layer-type-image[data-uid="j1kL-S35W"]').click(function () {
        
        jQuery('.wpc-text-layer[data-uid="TkCe-feK9"]').removeClass('taube');
        jQuery('.wpc-text-layer[data-uid="7dNd-ahQQ"]').removelass('taube');
        jQuery('.wpc-text-layer[data-uid="8Gt8-LI0E"]').removeClass('taube');
        jQuery('.wpc-text-layer[data-uid="Jsee-5zIb"]').removeClass('taube');
        

    });
    jQuery('.wpc-control-item.wpc-layer-type-image[data-uid="4QV5-izOL"]').click(function () {
        
        jQuery('.wpc-text-layer[data-uid="TkCe-feK9"]').removeClass('taube');
        jQuery('.wpc-text-layer[data-uid="7dNd-ahQQ"]').removeClass('taube');
        jQuery('.wpc-text-layer[data-uid="8Gt8-LI0E"]').removeClass('taube');
        jQuery('.wpc-text-layer[data-uid="Jsee-5zIb"]').removeClass('taube');
        

    });



   jQuery(".wpc-control-lists-inner[data-parent-uid='gchM-1UGd'] li").click(function () {

        if (jQuery(".wpc-control-lists-inner[data-parent-uid='gchM-1UGd'] .wpc-layer-type-image").hasClass("current")) {

            jQuery("[data-uid='zwKh-AZqo']").addClass('image-chosen');
            jQuery("[data-uid='gchM-1UGd'] .wpc-layer-type-text").addClass('image-chosen');
            console.log("had class");
       
      } else{
        jQuery(".wpc-carousel-stage-outer [data-uid='zwKh-AZqo']").removeClass('image-chosen');
        jQuery("[data-uid='gchM-1UGd'] .wpc-layer-type-text").removeClass('image-chosen');
        console.log("nope");
      
      }});
   
    
      jQuery("[data-uid='zwKh-AZqo'] input").click(function() { 
        console.log('clickcheck');
        jQuery(".subset[data-uid='zwKh-AZqo']").removeClass('image-chosen');
      });


      


      jQuery("[data-uid='zwKh-AZqo'] input").keyup(function() { 
       console.log('keyup');
       
        jQuery(".subset[data-uid='zwKh-AZqo']").removeClass('image-chosen');
       // jQuery("[data-uid='gchM-1UGd'] .wpc-layer-type-text").removeClass('image-chosen');

        var txtlen1 = jQuery("[data-uid = '7dNd-ahQQ'] p").val().length; 
        //var txtlen2 = jQuery("[data-uid = 'Kfml-rbdv'] p").val().length; 
        var txtlen2 = jQuery("[data-uid = '8Gt8-LI0E'] p").val().length; 
        var txtlen3 = jQuery("[data-uid = 'TkCe-feK9'] p").val().length; 
        
        
          jQuery("[data-uid = '7dNd-ahQQ'] p").removeClass();
          //jQuery("[data-uid = 'Kfml-rbdv'] p").removeClass();
          jQuery("[data-uid = '8Gt8-LI0E'] p").removeClass();
          jQuery("[data-uid = 'TkCe-feK9'] p").removeClass();
          

          
        if(txtlen1 <=3 ||txtlen2 <=3 || txtlen3 <=3){
          if(jQuery('option .gabriela:selected')){
           /*links*/ jQuery("[data-uid = '7dNd-ahQQ'] p").toggleClass("fontsize24");
            /*&*/ jQuery("[data-uid = '8Gt8-LI0E'] p").toggleClass("fontsize30");
            /*rechts*/ jQuery("[data-uid = 'TkCe-feK9'] p").toggleClass("fontsize24");
          }
          if(jQuery('option .allura:selected')){
            jQuery("[data-uid = '7dNd-ahQQ'] p").toggleClass("fontsize22");
            jQuery("[data-uid = '8Gt8-LI0E'] p").toggleClass("fontsize22");
            jQuery("[data-uid = 'TkCe-feK9'] p").toggleClass("fontsize22");
          }
          if(jQuery('option .alex-brush:selected')){
            jQuery("[data-uid = '7dNd-ahQQ'] p").toggleClass("fontsize20");
            jQuery("[data-uid = '8Gt8-LI0E'] p").toggleClass("fontsize22");
            jQuery("[data-uid = 'TkCe-feK9'] p").toggleClass("fontsize20");
          }
          
          
        }
        
        if(txtlen1 >=6 || txtlen2 >=6 || txtlen3 >=6){

          if(jQuery('option .gabriela:selected')){
            jQuery("[data-uid = '7dNd-ahQQ'] p").toggleClass("fontsize22");
            jQuery("[data-uid = '8Gt8-LI0E'] p").toggleClass("fontsize20");
            jQuery("[data-uid = 'TkCe-feK9'] p").toggleClass("fontsize22");
          }
          if(jQuery('option .allura:selected')){
            jQuery("[data-uid = '7dNd-ahQQ'] p").toggleClass("fontsize22");
            jQuery("[data-uid = '8Gt8-LI0E'] p").toggleClass("fontsize20");
            jQuery("[data-uid = 'TkCe-feK9'] p").toggleClass("fontsize22");
          }

          if(jQuery('option .alex-brush:selected')){
            jQuery("[data-uid = '7dNd-ahQQ'] p").toggleClass("fontsize20");
            jQuery("[data-uid = '8Gt8-LI0E'] p").toggleClass("fontsize20");
            jQuery("[data-uid = 'TkCe-feK9'] p").toggleClass("fontsize20");
          }

        }
        
      
    }); 
    
 
});